import React, { useState, useEffect, startTransition } from 'react';
import axios from 'axios';
import './ClientInfoForm.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { OtherMessageCard } from './OtherMessageCard';


function ClientInfoForm() {
    const [language, setLanguage] = useState('Spanish');
    const [logo, setLogo] = useState('')
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [optIn, setOptIn] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dataExists, setDataExists] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessageCard, setShowMessageCard] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [correctedNumber, setCorrectedNumber] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const [firstNameInput, setFirstNameInput] = useState('');
    const [middleName, setMiddleName] = useState('');



    const urlParams = new URLSearchParams(window.location.search);
    const accountEmail = urlParams.get('accountEmail');
    const providerName = urlParams.get('providerName');
    // console.log(providerName)



    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        );
        setIsDesktop(!isMobileDevice);
      
        const isApple = /iphone|ipad|ipod/i.test(userAgent.toLowerCase());
        setIsAppleDevice(isApple);
      }, []);



    useEffect(() => {
        loadLogo();
    
        if (otpVerified) {
            fetchData();
        }
    
        // if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
        //     handleSendOtp();
        // }
    }, [otpVerified, phoneNumber]);


    async function loadLogo() {
        console.log(providerName)
        try {
            const response = await axios.get('https://nproapiserver.herokuapp.com/getLogo', { params: { accountEmail, providerName } });
    
            if (response.data) {
                setLogo(response.data.smsConfirmLogo);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle the error for 404 status
            } else {
                console.error("An error occurred:", error);
            }
        }
    }
    

    const normalizeString = (str) => {
        const accents = 'ÁÀÂÄáàâäÉÈÊËéèêëÍÌÎÏíìîïÓÒÔÖóòôöÚÙÛÜúùûü';
        const without = 'AAAAaaaaEEEEeeeeIIIIiiiiOOOOooooUUUUuuuu';
    
        return str
            .split('')
            .map((char, index) => {
                const accentIndex = accents.indexOf(char);
                return accentIndex !== -1 ? without[accentIndex] : char;
            })
            .join('')
            .toLowerCase();
    };
    
    const fetchData = async () => {
        try {
            const response = await axios.get('https://nproapiserver.herokuapp.com/getClientDetails', { params: { phoneNumber, accountEmail, providerName, dateOfBirth, firstNameInput } });
    
            if (response.data) {
                // Extract the first last name
                const firstLastName = response.data.lastName.split(/[\s-]/)[0];
    
                // Prompt the user to enter their first last name for verification
                const userFirstLastNameInput = prompt(language === 'English' 
                    ? "Please enter your first last name for verification:"
                    : "Por favor, ingrese su primer apellido para verificación:");
    
                // Check if the input is null (when Cancel is clicked) or empty
                if (!userFirstLastNameInput) {
                    window.location.reload();
                    return;
                }
    
                // Normalize and compare both names
                if (normalizeString(userFirstLastNameInput) === normalizeString(firstLastName)) {
                    // If names match
                    setFirstName(response.data.firstName);
                    setMiddleName(response.data.middleName);
                    setLastName(response.data.lastName);
                    setEmail(response.data.email);
                    setOptIn(response.data.optIn);
                    setLanguage(response.data.language);
                    setDateOfBirth(response.data.dateOfBirth)
                    setDataExists(true);
    
                    if (!response.data.optIn) {
                        alert(
                            language === 'English'
                                ? "Please ensure you've checked the box if you wish to receive notifications and updates. If you accept to receive these, all fields must also be completed."
                                : "Por favor, asegúrese de haber marcado la casilla si desea recibir notificaciones y actualizaciones. Si acepta recibir estas notificaciones, todos los campos también deben completarse."
                        );
                    }
                } else {
                    // If names don't match
                    alert(
                        language === 'English'
                            ? "Your first last name does not match the information on our records. Please contact us to help you fix this issue."
                            : "Su primer apellido no coincide con la información en nuestros registros. Por favor, contáctenos para ayudarlo a solucionar este problema."
                    );
                    // Reload the app
                    window.location.reload();
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                alert(
                    language === 'English' 
                    ? "Your details have not been registered yet. You may do so now by filling all the information and clicking the SAVE button."
                    : "Sus detalles no han sido registrados aún. Puede hacerlo ahora completando toda la información y haciendo clic en el botón GUARDAR."
                );
                setOptIn(true); // Enabling the fields for input
            } else {
                console.error("An error occurred:", error);
            }
        }
    };
    
    

    const handleSendOtp = async () => {
        if (isSending) {
            return;  // Exit the function if OTP is currently being sent.
        }
    
        setIsSending(true);  // Indicate that the OTP is being sent.
    
        try {
            await axios.post('https://nproapiserver.herokuapp.com/sendOtp', { phoneNumber });
            setOtpSent(true);
        } catch (error) {
            if (error.response && error.response.status === 500) {
                alert(language === 'English' ? 'You entered an invalid phone number. Please check your number and try again.' : 'Ingresó un número de teléfono no válido. Verifique su número e intente de nuevo.');
                window.location.reload();
            } else {
                alert(language === 'English' ? 'Something went wrong. Please try again later.' : 'Algo salió mal. Inténtelo de nuevo más tarde.');
                window.location.reload();
            }
        }
    };

    // const correctPhoneNumber = (phoneNumber) => {
    //     if (phoneNumber.length === 10 && phoneNumber[0] !== '1') {
    //         return '1' + phoneNumber;
    //     } else if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
    //         return phoneNumber;
    //     } else {
    //         return null;
    //     }
    // };
    
    // const checkPhone = async () => {
    //     // Check if either phoneNumber or dateOfBirth is empty
    //     if (!phoneNumber || !dateOfBirth) {
    //         alert(language === 'English' ? "Please fill all the required fields." : "Por favor, complete todos los campos requeridos.");
    //         return;
    //     }
    
    //     const correctedNumber = correctPhoneNumber(phoneNumber);
        
    //     if (correctedNumber) {
    //         setPhoneNumber(correctedNumber);
    //         await handleSendOtp();
    //     } else {
    //         alert(language === 'English' ? 'Please enter a valid phone number.' : 'Por favor, ingrese un número de teléfono válido.');
    //         setPhoneNumber('');
    //     }
    // };
    
    
    // const checkPhone = async () => {
    //     // Check if either phoneNumber or dateOfBirth is empty
    //     if (!phoneNumber || !dateOfBirth) {
    //         alert("Please fill all the required fields.");
    //         return;
    //     }

    //     if (phoneNumber.length >= 12 || phoneNumber.length === 11 && phoneNumber[0] !== '1') {
    //         alert("Invalid phone number. Please check your number and try again.");
    //         return;
    //     }
    
    //     // If phoneNumber is filled and doesn't start with '1', prepend '1'
    //     if (phoneNumber.length === 10 && phoneNumber[0] !== '1') {
    //         setPhoneNumber('1' + phoneNumber);
    //     } else {
    //         handleSendOtp();
    //     }
    // };


    //-----------

    useEffect(() => {
        if (correctedNumber) {
            handleSendOtp();
            setCorrectedNumber(false);
        }
    }, [correctedNumber]);
      
    
  const correctedPhoneNumber = async (phoneNumber) => {
      setPhoneNumber('1' + phoneNumber);
      setCorrectedNumber(true);
  };
  
  
  const checkPhone = async () => {
    // Check if either phoneNumber or dateOfBirth is empty
    if (!phoneNumber || !dateOfBirth) {
        alert("Please fill all the required fields.");
        return;
    }
  
    // Validate phone number format
    if (phoneNumber.length === 10  && phoneNumber[0] !== '1') {
        // Valid 10-digit number, prepend '1'
        await correctedPhoneNumber(phoneNumber);
  
    } else if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
        // Valid 11-digit number starting with '1'
        handleSendOtp();
    } else {
        // Invalid number
        alert("Invalid phone number. Please enter a 10-digit number or an 11-digit number starting with '1'.");
        window.location.reload();
    }
  };
    


    

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post('https://nproapiserver.herokuapp.com/verifyOtp', { phoneNumber, otp });
            if (response.data.verified) {
                setOtpVerified(true);
                setOtpSent(false);
            } else {
                alert(language === 'English' ? "Incorrect OTP. Please try again." : "OTP incorrecto. Por favor intente de nuevo.");
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                alert(language === 'English' ? 'The entered number is incorrect. Please try again.' : 'El numero ingresado es incorrecto. Por favor trate de nuevo.');
            } else {
                // This is a generic error alert, you might want to display more specific messages based on the exact error or status code.
                alert(language === 'English' ? 'An error occurred. Please try again later.' : 'Ocurrió un error. Por favor intente de nuevo más tarde.');
            }
        }
    };
    

    const handleSaveUpdate = async () => {
        // Check if all the fields are filled out
        if (!firstName || !lastName || !email) {
            if (language === 'Español') {
                alert("Por favor llene todos los campos requeridos antes de guardar o actualizar.");
            } else {
                alert("Please fill out all required fields before saving or updating.");
            }
            return; // Stop execution if fields are missing
        }
    
        // Email format validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            if (language === 'Español') {
                alert("Por favor verifique su correo electrónico ya que el formato es inválido.");
            } else {
                alert("Please check your email as it's in an invalid format.");
            }
            return; // Stop execution if email format is wrong
        }
    
        try {
            await axios.post('https://nproapiserver.herokuapp.com/updateClientDetails', { language, optIn, firstName, middleName, lastName, email, phoneNumber, accountEmail, providerName, dateOfBirth});
    
            // Set the success message based on the language state.
            if (language === 'Español') {
                setMessage("Sus datos han sido actualizados exitosamente. ¡Gracias!");
            } else {
                setMessage("Your data has been successfully updated. Thank you!");
            }
    
            setShowMessageCard(true);  // Show the message card
    
            if (optIn) {
                // Logic to send email and text message.
                console.log("OptIn email and text will be sent!")
            }
        } catch (error) {
            console.error("There was an error updating the data:", error);
            if (language === 'Español') {
                alert("Hubo un error al actualizar los datos. Por favor intente de nuevo.");
            } else {
                alert("There was an error updating the data. Please try again.");
            }
        }
    };
    

    const isDateEmpty = !dateOfBirth || dateOfBirth === "";


    return (

        showMessageCard && message ? (
            <OtherMessageCard message={message}  />
         ) : (
            <>

        <div className="client-info-form">

            <div style={{ marginBottom: '20px', marginTop: '15px' }}>
            <img src={logo} alt="Logo" className="logo"/>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
  <ToggleButtonGroup
    value={language}
    exclusive
    onChange={(event, newLanguage) => {
      event.stopPropagation();
      if (newLanguage !== null) setLanguage(newLanguage);
    }}
    style={{ marginBottom: '20px', display: 'inline-flex' }}
  >
    <ToggleButton 
      value="Spanish" 
      style={{ 
        width: '80px',
        height: '30px',
        padding: '4px 8px',
        fontSize: '14px',
        color: language === 'Spanish' ? '#fff' : '#707070',
        backgroundColor: language === 'Spanish' ? '#1E90FF' : '#000'
      }}
    >
      Español
    </ToggleButton>
    <ToggleButton 
      value="English" 
      style={{ 
        width: '80px',
        height: '30px',
        padding: '4px 8px',
        fontSize: '14px',
        color: language === 'English' ? '#fff' : '#707070',
        backgroundColor: language === 'English' ? '#1E90FF' : '#000'
      }}
    >
      English
    </ToggleButton>
  </ToggleButtonGroup>
</div>


            </div>
    
            {otpVerified ? (
                <div>
{/* <label style={{ display: 'flex', alignItems: 'center', marginBottom: '60px', paddingRight: '25px' }}>
    <input
        type="checkbox"
        checked={optIn}
        onChange={e => setOptIn(e.target.checked)}
        disabled={!otpVerified}
        style={{ marginRight: '10px' }}
    />
    <span style={{ lineHeight: '1.5' }}>
        {language === 'English'
            ? "I agree to receive notifications and updates via email, text message, and voice calls."
            : "Estoy de acuerdo en recibir notificaciones y actualizaciones por correo electrónico, mensaje de texto y llamadas de voz."}
    </span>
</label> */}

<label style={{ display: 'flex', alignItems: 'center', marginBottom: '60px', paddingRight: '25px', WebkitAlignItems: 'center' }}>
    <input
        type="checkbox"
        checked={optIn}
        onChange={e => setOptIn(e.target.checked)}
        disabled={!otpVerified}
        style={{ marginRight: '10px', backgroundColor: '#fff', border: '1px solid #000', width: '16px', height: '16px', borderRadius: '3px', display: 'inline-block', verticalAlign: 'middle' }}
    />
    <span style={{ lineHeight: '1.5', display: 'inline-block', verticalAlign: 'middle' }}>
        {language === 'English'
            ? "I agree to receive notifications and updates via email, text message, and voice calls."
            : "Estoy de acuerdo en recibir notificaciones y actualizaciones por correo electrónico, mensaje de texto y llamadas de voz."}
    </span>
</label>




                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder={language === 'English' ? 'First Name' : 'Nombre'}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            disabled={!optIn}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder={language === 'English' ? 'Middle Name' : 'Segundo Nombre'}
                            value={middleName}
                            onChange={e => setMiddleName(e.target.value)}
                            disabled={!optIn}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder={language === 'English' ? 'Last Name' : 'Apellido'}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            disabled={!optIn}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
                    

{/* <div style={{ marginBottom: '15px', position: 'relative' }}>
    <input
        id="dateOfBirth"
        type="date"
        value={dateOfBirth}
        onChange={e => setDateOfBirth(e.target.value)}
        disabled={!optIn}
        style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
    />
    {isDateEmpty && (
        <span style={{
            position: 'absolute', 
            top: '10px', 
            left: '10px', 
            color: '#666666', 
            pointerEvents: 'none'
        }}>
            {language === 'English' ? 'Date of Birth' : 'Fecha de Nacimiento'}
        </span>
    )}
</div> */}

                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="email"
                            placeholder={language === 'English' ? 'Email' : 'Correo Electrónico'}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            disabled={!optIn}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
                    <div>
                        <button 
                            onClick={handleSaveUpdate} 
                            style={{ width: '100%', padding: '15px', fontSize: '16px' }}
                        >
                            {dataExists ? (language === 'English' ? 'UPDATE' : 'ACTUALIZAR') : (language === 'English' ? 'SAVE' : 'GUARDAR')}
                        </button>
                    </div>
                </div>
            ) : otpSent ? (
                <div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder={language === 'English' ? 'Enter OTP' : 'Ingrese OTP'}
                            value={otp}
                            onChange={e => setOtp(e.target.value)}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
                    <div>
                        <button 
                            onClick={handleVerifyOtp} 
                            style={{ width: '100%', padding: '15px', fontSize: '16px' }}
                        >
                            {language === 'English' ? 'Verify OTP' : 'Verificar OTP'}
                        </button>
                    </div>
                </div>
            ) : (
                <div>



                    {/* <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder={language === 'English' ? 'Enter your mobile phone number' : 'Ingrese su número de teléfono celular'}
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                        />
                    </div>
<div style={{ marginBottom: '15px', position: 'relative' }}>
    <input
        id="dateOfBirth"
        type="date"
        value={dateOfBirth}
        onChange={e => setDateOfBirth(e.target.value)}
        style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
    />
    {isDateEmpty && (
        <span style={{
            position: 'absolute', 
            top: '10px', 
            left: '10px', 
            color: '#666666', 
            pointerEvents: 'none'
        }}>
            {language === 'English' ? 'Date of Birth' : 'Fecha de Nacimiento'}
        </span>
    )}
</div> */}


<div style={{ marginBottom: '8px', marginTop: '-8px' }}>
  <input
    type="text"
    placeholder={language === 'English' ? 'Enter your mobile phone number' : 'Ingrese su número de teléfono celular'}
    value={phoneNumber}
    onChange={e => setPhoneNumber(e.target.value)}
    style={{
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        textAlign: isAppleDevice ? 'center' : 'left' // Center on Apple devices, left-align on others
      }}
  />
</div>

<div style={{ marginBottom: '8px'}}>
  <input
    type="text"
    placeholder={language === 'English' ? 'Enter your first name' : 'Ingrese su primer nombre'}
    value={firstNameInput}
    onChange={e => setFirstNameInput(e.target.value)}
    style={{
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        textAlign: isAppleDevice ? 'center' : 'left' // Center on Apple devices, left-align on others
      }}
  />
</div>

<div
  style={{
    marginBottom: '50px',
    position: 'relative', // Keep the container positioned relatively
    width: '100%',
  }}
>
<input
  id="dateOfBirth"
  type="date"
  value={dateOfBirth}
  onChange={(e) => setDateOfBirth(e.target.value)}
  style={{
    width: '100%',
    padding: '15px 10px 10px 10px', // Adjust padding to make space for the label
    boxSizing: 'border-box',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #ccc',
    borderRadius: '10px', // Keep the rounded corners
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    margin: 0,
    height: '40px',
    textAlign: 'left',
    WebkitTextAlign: 'left',
    MozTextAlign: 'left',
  }}
/>

  {!dateOfBirth && !isDesktop && (
    <label
      htmlFor="dateOfBirth"
      onClick={() => document.getElementById('dateOfBirth').focus()}
      style={{
        position: 'absolute', // Position the label over the input
        top: '10px',
        left: '11px',
        color: isAppleDevice ? '#AAAAAA' : '#707070',
        fontSize: '16px',
        cursor: 'text',
        pointerEvents: 'none', // Allow clicks to pass through to the input
      }}
    >
      {language === 'English' ? 'Date of birth' : 'Fecha de nacimiento'}
    </label>
  )}
</div>




                    <div>
                    <button 
                            onClick={checkPhone} 
                            disabled={isSending} 
                            style={{ width: '100%', padding: '15px', fontSize: '16px' }}
                        >
                            {isSending ? (language === 'English' ? 'Sending...' : 'Enviando...') : (language === 'English' ? 'Send OTP' : 'Enviar OTP')}
                    </button>

                    </div>
                </div>
            )}
            
        </div>
        </>
   )
);
    
    
}



export default ClientInfoForm;
